<template>
  <div>
    <Loader :visible="loading" />
    <v-row>
      <v-col md="5" offset-md="4" v-if="!loading">
        <div class="title mt-5">Encuesta de Transformación Digital</div>
        <div class="subtitle">
          Responde esta breve encuesta para que tu empresa conozca el nivel de madurez digital y sus brechas.
        </div>
        <div class="subtitle-bold">
          Selecciona la nota para cada afirmación, considerando una escala de 1 a 10, donde 1 corresponde a “Totalmente
          en desacuerdo” y 10 a “Totalmente de acuerdo”.
        </div>
        <answers-list class="mt-4" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import AnswersList from "@/components/survey/AnswersListInvited";
import { mapGetters } from "vuex";
export default {
  components: {
    AnswersList,
    Loader,
  },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    if (Object.keys(this.getSurveyInvitedRegistration).length != 0) {
      this.loading = false;
    } else {
      this.$router.go(-1);
    }
  },
  computed: {
    ...mapGetters("InvitationsStore", ["getSurveyInvitedRegistration"]),
  },
};
</script>
<style lang="css" scoped>
.breadcrumb {
  text-align: center;
  font-size: 16px;
  font-family: Roboto Condensed;
  color: #7319d5;
}
.title {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 25px !important;
  line-height: 150%;
  color: #333333;
  text-align: center;
}
.subtitle {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 24px;
  color: #333333;
  text-align: center;
  margin-top: 10px;
}
.subtitle-bold {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px !important;
  color: #333333;
  margin-top: 35px;
  margin-left: 10px;
}
</style>
